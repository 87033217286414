document.addEventListener("DOMContentLoaded", function () {
   
   // text change
    document.querySelector('.js-change-text').addEventListener('click', function(e) {

      e.preventDefault();
      document.querySelector('.company-info-content__body').classList.add('company-info-content__body--action');
    });

    // menu toggle
    document.querySelector('.js-mobile-button').addEventListener('click', function() {

      document.querySelector('.block-menu').classList.add('block-menu--active');
    });

    document.querySelector('.js-menu-close').addEventListener('click', function() {

      document.querySelector('.block-menu').classList.remove('block-menu--active');
    });

    document.querySelector('.js-block-menu-hide').addEventListener('click', function() {

      document.querySelector('.block-menu').classList.remove('block-menu--active');
    });
});
